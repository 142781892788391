import { STATUS } from "constants/fetchStatus";
import { fetchExchangePair } from "api/pairs";
import useFetch from "hooks/useFetch";
import "./styles.scss";

const fallbackImg =
  "https://cdn.jsdelivr.net/npm/cryptocurrency-icons@0.18.1/32/icon/act.png";

export default function CryptoIconWithFetch({ pair, exchange }) {
  const splitedPair = pair.split(":")[0];
  const { data, status } = useFetch(fetchExchangePair, exchange, splitedPair);
  const url = data
    ? `https://www.cryptocompare.com/${data.imageUrl}`
    : fallbackImg;
  return (
    <>
      {status === STATUS.ERROR && "Error"}
      <div className="cp-container">
        {data ? (
          <img
            style={{ width: "28px", height: "28px" }}
            src={url}
            alt={pair.split("-")[0] || "coin"}
          />
        ) : (
          <div style={{ width: "28px", height: "28px" }}></div>
        )}
        <div className="name">{pair}</div>
      </div>
    </>
  );
}
