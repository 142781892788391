import { useState, useEffect } from 'react'

// React Redux
import { useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Dashboard layout components
import AdminMessages from "./components/AdminMessages";
import News from "./components/News";
import WalletSummary from "./components/WalletSummary";
import MarketData from "./components/MarketData";
import ArbitrageOpportunities from "./components/ArbitrageOpportunities";
// import FavCoins from "./components/FavCoins";
import { createChat } from '@n8n/chat';
import '@n8n/chat/style.css';


// Data
import clientConfig from "utils/clientConfig";

export default function Dashboard() {
  const trades = useSelector((state) => state.orders.data.trades.spot);
  const arbitrageActions = useSelector(
    (state) => state.arbitration.actions.data
  );

  const [openTrades, setOpenTrades] = useState([]);
  const [closedTrades, setClosedTrades] = useState([]);

  useEffect(() => {
    createChat({
      webhookUrl: `https://n8n.ctrading.io/webhook/${process.env.REACT_APP_N8N_ID}/chat`
    });
  }, []);


  useEffect(() => {
    if (trades) {
      setOpenTrades(trades.filter((trade) => trade.status === "open"))
      setClosedTrades(trades.filter((trade) => trade.status === "closed"))
    }
  }, [trades])


  return (
    <DashboardLayout>
      <SoftBox id="home">
        {clientConfig.useTrading ? (
          <SoftBox id="operations" mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} xl={4}>
                <MiniStatisticsCard
                  title={{ text: "Trades (open)" }}
                  count={openTrades.length}
                  percentage={{ color: "success", text: "" }}
                  icon={{ color: "info", component: "toll" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} xl={4}>
                <MiniStatisticsCard
                  title={{ text: "Trades (closed)" }}
                  count={closedTrades.length}
                  percentage={{ color: "success", text: "" }}
                  icon={{ color: "info", component: "timeline" }}
                />
              </Grid>
              <Grid item xs={12} sm={4} xl={4}>
                <MiniStatisticsCard
                  title={{ text: "Arbitrage actions" }}
                  count={arbitrageActions.length}
                  percentage={{ color: "success", text: "" }}
                  icon={{
                    color: "info",
                    component: "bolt",
                  }}
                />
              </Grid>
            </Grid>
          </SoftBox>
        ) : (
          ""
        )}
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid id="wallet" item xs={12} sm={6} xl={6}>
              <WalletSummary />
            </Grid>
            <Grid id="adminMessages" item xs={12} sm={6} xl={6}>
              <AdminMessages />
            </Grid>
            <Grid id="news" container item spacing={3} xs={12}>
              <Grid item xs={12} sm={6} xl={6}>
                <News />
              </Grid>
              <Grid item xs={12} sm={6} xl={6}>
                <MarketData />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={6} xl={6}>
            <FavCoins />
          </Grid> */}
            {clientConfig.useArbitrage ? (
              <Grid item xs={12} sm={12} xl={12}>
                <ArbitrageOpportunities />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}
