import SoftTypography from "components/SoftTypography";
import roundNum from "utils/roundNum";

export default function Price({ price, pair }) {
    const parsedPair = pair.match(/([^:\-]+)$/);
    return (
        <SoftTypography className="table-number">
            {`${roundNum(price)} ${price ? parsedPair[1] : ""}`}
        </SoftTypography>
    )
}